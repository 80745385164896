<template>
  <button
    class="app-button"
    :disabled="isDisabled"
    @click="click"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'appButton',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    click() {
      this.$emit('click')
    },
  },
}
</script>
