<template>
  <div :class="['app-input', ...dynamicWrapClasses]">
    <span :class="['app-input__label', { 'active': isActive }]">{{ label }}</span>
    <input
        :value="value"
        @input="handleChange"
        :type="type"
        :inputmode="inputMode"
        :min="min"
        :max="max"
        :maxlength="maxLength"
        :disabled="isDisabled"
        :required="isRequired"
        @focus="isActive = true"
        :[dataAttr]="true"
        @blur="onBlur"
        @keydown.enter="enterPressed"
    />
    <slot></slot>
    <p v-if="isError" class="app-input__error">
      {{ textError }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'appInput',
  emits: [
    'input',
    'enter-pressed'
  ],
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [ String, Number ],
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    maxLength: {
      type: [ String, Number ],
    },
    textError: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    dataAttr: {
      type: String,
      default: '',
    },
    isUnderlineStyle: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    inputMode: {
      type: String,
      default: 'text',
    }
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    dynamicWrapClasses() {
      return [
          { 'error': this.isError },
          { 'disabled': this.isDisabled },
          { 'app-input_underline': this.isUnderlineStyle },
      ]
    },
  },
  methods: {
    onBlur() {
      if (this.value === '' || this.value === null || this.value === undefined) {
        this.isActive = false;
      }
    },
    checkIsBlurred() {
      if (
          (typeof this.value === 'string' && this.value.length > 0) ||
          (typeof this.value === 'number' &&  this.value >= 0)
      ) this.isActive = true
    },
    handleChange(e) {
      let emittedValue = ''

      if (this.type === 'number' && typeof this.min !== 'undefined' && typeof this.max !== 'undefined') {
        emittedValue = e.target.value < this.min
            ? e.target.value = this.min
            : e.target.value > this.max
              ? e.target.value = this.max
              : e.target.value
      } else {
        emittedValue = e.target.value
      }
      this.$emit('input', emittedValue)
    },
    enterPressed() {
      this.$emit('enter-pressed')
    },
  },
  updated() {
    this.checkIsBlurred()
  },
  mounted() {
    this.checkIsBlurred()
  }
}
</script>
