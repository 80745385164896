var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-product-slider",
      on: {
        mousedown: function($event) {
          return _vm.startSwipe($event, "mouse")
        },
        touchstart: function($event) {
          return _vm.startSwipe($event, "finger")
        },
        mousemove: function($event) {
          return _vm.swipe($event, "mouse")
        },
        touchmove: function($event) {
          return _vm.swipe($event, "finger")
        },
        mouseup: _vm.stopSwipe,
        touchend: _vm.stopSwipe
      }
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "app-product-slider__skeleton skeleton-animation"
      }),
      _vm._v(" "),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading"
          }
        ],
        staticClass: "app-product-slider__image",
        attrs: {
          src: _vm.getPath(),
          alt: "product " + _vm.currentIndex,
          draggable: "false"
        },
        on: { load: _vm.onLoad }
      }),
      _vm._v(" "),
      _vm.images.length > 1
        ? _c(
            "div",
            { staticClass: "app-product-slider__pagination" },
            _vm._l(_vm.images, function(_, index) {
              return _c("button", {
                key: index,
                class: _vm.paginationButtonClass(index),
                on: {
                  click: function($event) {
                    return _vm.changeSlide(index)
                  }
                }
              })
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-596ba237", { render: render, staticRenderFns: staticRenderFns })
  }
}