<template>
  <VueDatePicker
    class="app-datepicker"
    v-model="date"
    :color="mainColor"
    no-header
    placeholder="Выберите дату"
    :min-date="minDate"
    :max-date="maxDate"
    fullscreen-mobile
    :name="label"
    mobile-breakpoint="425"
  >
    <template v-slot:activator="{ date }">
      <app-input
        :label="label"
        ref="activator"
        type="button"
        :value="date"
        :is-required="isRequired"
      >
      </app-input>
    </template>
  </VueDatePicker>
</template>
<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import appInput from '@ui/app-input/appInput.vue'

export default {
  name: 'AppDatepicker',
  components: { VueDatePicker, appInput },
  emits: ['select'],
  props: {
    minDate: {
      type: [Date, String],
      default: '1900-01-01',
    },
    maxDate: {
      type: [Date, String],
    },
    label: {
      type: String,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
    }
  },
  computed: {
    mainColor() {
      // принимает только hex, переменные не распознает
      return '#333333'
    },
  },
  watch: {
    date() {
      this.$emit('select', this.date)
    }
  }
}
</script>
