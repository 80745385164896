<template>
  <div
    class="app-product-slider"
    @mousedown="startSwipe($event, 'mouse')"
    @touchstart="startSwipe($event, 'finger')"
    @mousemove="swipe($event, 'mouse')"
    @touchmove="swipe($event, 'finger')"
    @mouseup="stopSwipe"
    @touchend="stopSwipe"
  >
    <div v-show="isLoading" class="app-product-slider__skeleton skeleton-animation"></div>
    <img
      v-show="!isLoading"
      class="app-product-slider__image"
      :src="getPath()"
      :alt="`product ${currentIndex}`"
      draggable="false"
      @load="onLoad"
    >
    <div v-if="images.length > 1" class="app-product-slider__pagination">
      <button
        v-for="(_, index) in images"
        :key="index"
        :class="paginationButtonClass(index)"
        @click="changeSlide(index)"
      >
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppProductSlider',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    needResetComponent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentIndex: 0,
      startX: 0,
      isSwiping: false,
      isLoading: true,
    }
  },
  computed: {
    ...mapState({
      routeToImageFolder: state => state.pathImg,
    }),
    currentImageHash() {
      return this.images[this.currentIndex]
    },
  },
  watch: {
    needResetComponent() {
      this.currentIndex = 0
      this.isLoading = true
    },
  },
  methods: {
    getPath() {
      return this.routeToImageFolder + this.currentImageHash
    },
    onLoad() {
      this.isLoading = false
    },
    paginationButtonClass(index) {
      return ['app-product-slider__pagination-button', { active: this.currentIndex === index }]
    },
    changeSlide(index) {
      this.currentIndex = index
    },
    startSwipe($event, touchType) {
      touchType === 'mouse' ? this.startX = $event.clientX : this.startX = $event.touches[0].clientX
      this.isSwiping = true
    },
    swipe($event, touchType) {
      if (this.isSwiping) {
        let distance
        touchType === 'mouse'
          ? distance = $event.clientX - this.startX
          : distance = $event.touches[0].clientX - this.startX

        if (distance > 100 && this.currentIndex !== 0) {
          this.changeSlide(this.currentIndex - 1)
          this.stopSwipe()
        } else if (distance < -100 && this.currentIndex < this.images.length - 1) {
          this.changeSlide(this.currentIndex + 1)
          this.stopSwipe()
        }
      }
    },
    stopSwipe() {
      this.isSwiping = false
    },
  },
}
</script>
