<template>
  <label class="app-radio">
    <input
      class="app-radio__input_real"
      v-model="isChecked"
      type="radio"
      :name="radioData.groupName"
      :value="radioData.value"
      :checked="isChecked"
      @change="emitActiveValue($event)"
    >
    <span class="app-radio__input_custom" />
    {{ radioData.label }}
  </label>
</template>

<script lang="js">
export default {
  name: 'AppRadio',
  props: {
    radioData: {
      type: Object,
      required: true,
      default: () => ({
        groupName: '',
        value: '',
        label: '',
        isDefaultChecked: false,
      }),
    },
  },
  emits: ['change'],
  data() {
    return {
      isChecked: false,
    }
  },
  mounted() {
    this.isChecked = this.radioData.isDefaultChecked
  },
  methods: {
    emitActiveValue($event) {
      this.$emit('change', $event.target.value)
    },
  },
}
</script>
