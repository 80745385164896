import store from '@store'
import * as actions from '@store/types/actions-types'
import * as mutations from '@store/types/mutations-types'

// TODO: временное решение, сделано для переиспользования (этот файл удалится)
// QS-1208
export default {
  computed: {
    fullStreet: {
      get() { return this.$store.state.daData.address.fullStreet },
      set(v) {
        this.$store.commit(mutations.UPDATE_DA_DATA_ADDRESS_FULL_STREET, v)
        this.$store.commit(mutations.CLEAR_DA_DATA_DELIVERY_PRICES_LOCAL)
        store.dispatch(actions.LOAD_STREET_DA_DATA)
      },
    },
  },
  methods: {
    setLoadedAddressInCustomer(loadedAddress, isCreateOrderPage) {
      const { city, street, house } = this.$store.state.daData.address
      store.commit(mutations.SET_LOADED_ADDRESS_IN_ADDRESS_LOCAL, loadedAddress)
      if (isCreateOrderPage) {
        if (city !== '' && house !== '' && street !== '') {
          store.dispatch(actions.CHOOSE_ADDRESS_COURIER).then(
            () => {
              store.dispatch(actions.LOAD_DELIVERY_PRICES)
            },
          )
        }
      } else {
        store.dispatch(actions.LOAD_DELIVERY_PRICES)
      }
      store.commit(mutations.CLEAR_LOAD_ADDRESSES)
    },
  },
}
