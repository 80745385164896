var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-radio-tape" },
    _vm._l(_vm.radioData, function(value) {
      return _c(
        "label",
        {
          key: value,
          staticClass: "app-radio-tape__input",
          class: { active: _vm.checked === value }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checked,
                expression: "checked"
              }
            ],
            staticClass: "app-radio-tape__input_real",
            attrs: { type: "radio", name: value },
            domProps: { value: value, checked: _vm._q(_vm.checked, value) },
            on: {
              change: [
                function($event) {
                  _vm.checked = value
                },
                function($event) {
                  return _vm.toggleActiveElement($event)
                }
              ]
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "app-radio-tape__input_custom" }, [
            _vm._v("\n      " + _vm._s(value) + "\n    ")
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6b3926a2", { render: render, staticRenderFns: staticRenderFns })
  }
}